<template>
    <div>
        <div class="bg_img contact_img"></div>
        <div class="contact">
            <h1 class="title">联系我们</h1>
            <div class="line"></div>
            <ul>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_mail.png">
                    </div>
                    <h4>电子邮件</h4>
                    <div class="un_line"></div>
                    <p class="ctr_lineHeight">xcwl@xcwlkj.com</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_phone.png">
                    </div>
                    <h4>商务合作电话</h4>
                    <div class="un_line"></div>
                    <p class="ctr_lineHeight">0571-87092827</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_addr.png">
                    </div>
                    <h4>公司地址</h4>
                    <div class="un_line"></div>
                    <p>杭州市西湖区塘苗路18号华星现代产业园B座508、509室</p>
                </li>
            </ul>
            <div class="map">
                <div class="map_box">
                    <div class="point">
                        <img src="../../assets/img/map_point.png">
                    </div>
                    <div class="shadow" :style="showImg"></div>
                </div>
                <div class="addr">
                    <h5>联系地址</h5>
                    <p>杭州市西湖区塘苗路18号华星现代产业园B座508/509室</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "fourth.vue",
        data(){
            return{
                showImg:{
                    backgroundImage:'url(' + require('../../assets/img/shadow.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                }
            }
        }
    }
</script>

<style scoped>
    @import '../../public.css';

</style>
