<template>
    <div>
        <div class="bg_img about_img"></div>
        <div class="about">
            <h1 class="title">关于兴畅</h1>
            <div class="line"></div>
            <div class="introduce">
                <div class="info">
                    <p>兴畅网络成立于2015年，自公司成立以来，一直专注于应用平台、大数据、物联网等领域,为其提供相应的技术与支持服务。</p>
                    <p>公司拥有一支稳定的技术研发团队，围绕公司业务核心方向进行了持续的技术攻关和产品研发，具备行业一流能力，目前公司已拥有二十多项软著，涉及通用开发平台、物联接入平台、基础支撑平台、数据开发平台等领域。公司一向以“重诺守信，严谨求是”为准则，秉承互信共赢的价值观，追求与合作伙伴建立长期良好的合作关系。</p>
                    <p>企业资质：国家高新技术企业、双软企业、ISO9001质量管理体系认证、ISO27001信息安全管理体系认证、CMMI3软件能力成熟度认证。</p>
                </div>
                <div class="img">
                    <img src="../../assets/img/introduce.jpg">
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "second.vue",
        data(){
            return{

            }
        }
    }
</script>

<style scoped>
 @import "../../public.css";

</style>
