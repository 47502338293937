<template>
    <div>
        <div class="bg_img service_img"></div>
        <div class="service">
            <h1 class="title">我们的服务</h1>
            <div class="line"></div>
            <ul class="ul-box">
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_empolder.png">
                    </div>
                    <div class="info">
                        <h3>软件开发</h3>
                        <p>以技术团队和技术资源优势，协助您完成软件系统的方案设计、开发测试</p>
                    </div>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_product.png">
                    </div>
                    <div class="info">
                        <h3>产品咨询</h3>
                        <p>通过需求采集、解决方案、原型设计、视觉设计，以明确并完善您的需求</p>
                    </div>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_technology.png">
                    </div>
                    <div class="info">
                        <h3>技术咨询</h3>
                        <p>根据您的业务和技术要求，制定技术规划、技术选型、技术方案、系统架构设计等</p>
                    </div>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../assets/img/icon_sales.png">
                    </div>
                    <div class="info">
                        <h3>产品销售</h3>
                        <p>公司现有拥有知识产权的成熟产品，可以灵活授权、定制实施及知识产权共享</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
    export default {
        name: "third.vue"
    }
</script>

<style scoped>
    @import '../../public.css';

</style>
