<template>
    <div>
        <div class="top-box">
            <div class="nav">
                <div class="logo"></div>
                <el-tabs v-model="activeName" @tab-click="handleClick" class="large_screen_nav">
                    <el-tab-pane label="首页" name="first"/>
                    <el-tab-pane label="关于兴畅" name="second"/>
                    <el-tab-pane label="服务支持" name="third"/>
                    <el-tab-pane label="联系我们" name="fourth"/>
                </el-tabs>
                <div class="small_screen_nav">
                    <div class="small_menu"></div>
                    <el-tabs v-model="activeName" @tab-click="handleClick" :tab-position="tabPosition" class="small_nav">
                        <el-tab-pane label="首页" name="first"/>
                        <el-tab-pane label="关于兴畅" name="second"/>
                        <el-tab-pane label="服务支持" name="third"/>
                        <el-tab-pane label="联系我们" name="fourth"/>
                    </el-tabs>
                </div>
            </div>
        </div>
        <first v-if="activeName==='first'"/>
        <second v-if="activeName==='second'"/>
        <third v-if="activeName==='third'"/>
        <fourth v-if="activeName==='fourth'"/>


        <div :style="conTop" class="footer-box">
            <img src="../assets/foot-logo.png" alt="" class="foot-logo">
            <div class="foot-title">大数据及智慧产业解决方案供应商</div>
            <div class="foot-detail">
                <div class="foot-flex">
                    <img src="../assets/email.png" class="detail-icon" alt="">
                    <span>xcwl@xcwlkj.com</span>
                </div>
                <div class="foot-flex ctr_margin">
                    <img src="../assets/phone.png" class="detail-icon" alt="">
                    <span>0571-87092827</span>
                </div>
                <div class="foot-flex">
                    <img src="../assets/address.png" class="detail-icon" alt="">
                    <span>杭州市西湖区塘苗路18号华星现代产业园B座508、509室</span>
                </div>
            </div>
        </div>
        <div class="bottom-box large_screen_cr">Copyright (C) 2015 杭州兴畅网络科技有限公司 <a style="color:#fff" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备15044044号</a ></div>
        <div class="bottom-box small_screen_cr">Copyright (C) 2015 杭州兴畅网络科技有限公司 <br><a style="color:#fff" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备15044044号</a ></div>
    </div>
</template>

<script>
    import first from "./components/first";
    import second from "./components/second";
    import third from "./components/third";
    import fourth from "./components/fourth";
    export default {
        name: "home.vue",
        components:{
            first,second,third,fourth
        },
        data(){
            return{
                tabPosition: 'right',
                activeName:'first',
                conTop: {
                    backgroundImage:'url(' + require('../assets/footer.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                aboutImg:{
                    backgroundImage:'url(' + require('../assets/img/bg_about.jpg') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                serviceImg:{
                    backgroundImage:'url(' + require('../assets/img/bg_service.jpg') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                contactImg:{
                    backgroundImage:'url(' + require('../assets/img/bg_contact.jpg') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                }
            }
        },
        methods: {
            handleClick(tab, event) {
                //console.log(tab, event);
            }
        }
    }
</script>

<style>
    @import '../public.css';
    @import '../home.css';
</style>
