<template>
    <div>
        <div class="banner-box">
            <el-carousel class="pic_box">
                <el-carousel-item v-for="(item,index) in imgBannerList" :key="'item'+index">
                    <div :style="{background:item.backgroundImage}" class="banner_box">
                        <div class="banner">
                            <img :src="item.textImage" alt="">
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="kind-box">
            <div class="kind-name">四大产品体系</div>
            <div class="sign"></div>
        </div>

        <div class="content-box system">
            <div class="data-box">
                <div class="title-box">
                    <img :src="require('../../assets/data.png')" class="icon-img" alt="">
                    <div class="icon-title">数据开发平台</div>
                </div>
                <div class="title-content">Xc-Data数据PaaS平台</div>
                <div class="more"></div>
                <div class="title-end">
                    <h4>Xc-Data数据PaaS平台</h4>
                    <p>数据应用开发基础设施，协助企业降低构建数据平台技术门槛，集成数据交换、数据开发、数据治理、数据服务于一身的一站式数据建设工具，支持企业从“0-1”构建数据中台，为企业的业务创新持续赋能。</p>
                </div>
                <div class="pic" :style="data_img"></div>
            </div>
            <div class="data-box">
                <div class="title-box">
                    <img :src="require('../../assets/Internet.png')" class="icon-img" alt="">
                    <div class="icon-title">物联网平台</div>
                </div>
                <div class="title-content">Xc-Link统一接入平台</div>
                <div class="more"></div>
                <div class="title-end">
                    <h4>Xc-Link统一接入平台</h4>
                    <p>为物联网设备提供安全可靠的连接通信能力，支持海量设备连接，采集设备数据到云端；向上提供云端API，各类服务端应用通过云端API将指令下发给设备，实现远程遥控。同时提供了物模型定义、设备管理、规则引擎等，实现灵活的物联通信。</p>
                </div>
                <div class="pic" :style="Internet_img"></div>
            </div>
            <div class="data-box">
                <div class="title-box">
                    <img :src="require('../../assets/exploit.png')" class="icon-img" alt="">
                    <div class="icon-title">应用开发平台</div>
                </div>
                <div class="title-content">Xc-Dev开发框架与平台</div>
                <div class="more"></div>
                <div class="title-end">
                    <h4>Xc-Dev开发框架与平台</h4>
                    <p>微服务开发平台，提供了业务模型定义、接口定义、页面定义，同时也提供了协同合作、快捷部署等。将设计语言转成开发语言，实现快速开发，同时加强了开发文档、代码、部署等标准应用，能够快速推进高扩展高性能高可用的系统构建。</p>
                </div>
                <div class="pic" :style="exploit_img"></div>
            </div>

            <div class="data-box">
                <div class="title-box">
                    <img :src="require('../../assets/use.png')" class="icon-img" alt="">
                    <div class="icon-title">基础支撑平台</div>
                </div>
                <div class="title-content">Xc-Bpt基础支撑底座平台</div>
                <div class="more"></div>
                <div class="title-end">
                    <h4>Xc-Bpt基础支撑底座平台</h4>
                    <p>将消息服务、文件服务、日志服务、缓存服务、支付服务、短信服务、即时通讯、规则引擎、流程引擎、地图引擎、流量服务等基础服务，插拔式的集成在Xc-Bpt底座上，高内聚低耦合，为大型系统构建良好的基础实施，让系统实现更专注于业务。</p>
                </div>
                <div class="pic" :style="use_img"></div>
            </div>
        </div>

        <div class="bg_gray">
            <div class="kind-box">
                <div class="kind-name">解决方案</div>
                <div class="sign"></div>
            </div>
            <div class="content-box solve">
                <div class="item-box" v-for="(item,index) in  imgList" :key="'item'+index">
                    <img class="item-img" :src="item.url" alt="">
                    <div class="item-name">{{item.name}}</div>
                </div>
            </div>
        </div>
        

        <div class="kind-box">
            <div class="kind-name">合作品牌</div>
            <div class="sign"></div>
        </div>
        <div class="content-box brand">
            <div class="logo-img-box" v-for="(item,index) in  logoList" :key="'em'+index">
                <img class="logo-img" :src="item" alt="">
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        name: "first.vue",
        data(){
            return{
                imgBannerList:[
                    { backgroundImage:'url(' + require('../../assets/banner1.png') + ')', textImage:require('../../assets/banner1_text.png') },
                    { backgroundImage:'url(' + require('../../assets/banner2.png') + ')', textImage:require('../../assets/banner2_text.png') },
                ],
                data_img:{
                    backgroundImage:'url(' + require('../../assets/data-pg.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100% 100%',
                },
                Internet_img:{
                    backgroundImage:'url(' + require('../../assets/Internet-pg.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                exploit_img:{
                    backgroundImage:'url(' + require('../../assets/exploit-pg.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                use_img:{
                    backgroundImage:'url(' + require('../../assets/use-pg.png') + ')',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                },
                imgList:[
                    { name:'宁波市人大“双下沉”数字驾驶舱', url: require('../../assets/both.png')},
                    { name:'湘村大数据监控中心', url: require('../../assets/village.png')},
                    { name:'智慧灯杆管理平台', url: require('../../assets/road.png')},
                    { name:'代表人物画像', url: require('../../assets/person.png')},
                    { name:'蓄电池梯次利用管理系统数据统计',url: require('../../assets/battery.png')},
                    { name:'工程进度一张图', url: require('../../assets/project.png')}
                ],
                logoList:[
                    require('../../assets/1.png'),
                    require('../../assets/2.png'),
                    require('../../assets/3.png'),
                    require('../../assets/4.png'),
                    require('../../assets/5.png'),
                    require('../../assets/6.png'),
                    require('../../assets/7.png'),
                    require('../../assets/8.png'),
                    require('../../assets/9.png'),
                    require('../../assets/10.png'),
                    require('../../assets/11.png'),
                    require('../../assets/12.png'),
                    require('../../assets/13.png'),
                    require('../../assets/14.png'),
                    require('../../assets/15.png'),
                    require('../../assets/16.png'),
                    require('../../assets/17.png'),
                    require('../../assets/18.png'),
                    require('../../assets/19.png'),
                    require('../../assets/20.png'),
                ],
            }
        }
    }
</script>

<style scoped>
 @import "../../first.css";

</style>
